import { PropsWithChildren, Ref, useState } from 'react';
import { concat } from '../../../../utils';
import { BarsIcon } from '../../../../utils/icons';
import { Button } from '../../../controls';
import { LoadingIndicator, Tabs, TabsProps } from '../../views';
import styles from './AppScreenContainer.module.scss';
import { Header, NavPanel } from './components';

export type AppScreenContainerProps<Tabs extends string> = PropsWithChildren<{
  title: string;
  className?: {
    container?: string;
    content?: string;
    main?: string;
  };
  titleAction?: JSX.Element;
  isLoading?: boolean;
  tabs?: TabsProps<Tabs>;
  refs?: {
    main?: Ref<HTMLDivElement>;
  };
}>;

export const AppScreenContainer = <Tabs extends string>( {
  title,
  children,
  className,
  titleAction,
  isLoading,
  tabs,
  refs
}: AppScreenContainerProps<Tabs> ) => {
  const [ isNavPanelOpen, setNavPanelOpen ] = useState( false );

  const toggleNavPanelOpen = ( value: boolean ) => () => setNavPanelOpen( value );

  return (
    <div className={concat( styles['container'], className?.container )}>
      <NavPanel isOpen={isNavPanelOpen} />
      <div className={concat( styles['content'], className?.content )}>
        <Header />
        <div className={styles['title-container']}>
          <h1>{title}</h1>
          {titleAction && <div className={styles['action']}>{titleAction}</div>}
        </div>
        {tabs && <Tabs {...tabs} />}
        <main
          className={concat( styles['main'], className?.main )}
          ref={refs?.main}
        >
          {isLoading ? <LoadingIndicator box /> : children}
        </main>
      </div>
      <div className={styles['nav-panel-toggle']}>
        <Button
          text={<BarsIcon />}
          onClick={toggleNavPanelOpen( !isNavPanelOpen )}
        />
      </div>
    </div>
  );
};
