import { ArrowDownFillIcon } from './ArrowDownIcon';
import styles from './DateSelect.module.scss';

export const DateSelectInput = (
  props: React.HTMLProps<HTMLInputElement>,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ref: React.Ref<HTMLInputElement>
) => {
  return (
    <div className={styles['date-select-input']}>
      <input
        {...props}
        ref={ref}
      />
      <ArrowDownFillIcon className={styles['icon']} />
    </div>
  );
};
