import { Mutex } from 'async-mutex';
import axios from 'axios';
import { authActions } from '../../slices';
import { store } from '../../store';
import { TokensPair } from '../models';

export const baseURL =
  process.env.NODE_ENV === 'development'
    ? process.env.REACT_APP_BACKEND_BASEURL_LOCAL
    : process.env.REACT_APP_BACKEND_BASEURL;

const client = axios.create( { baseURL } );

const refreshMutex = ( function () {
  const mutex = new Mutex();
  const refresh = async () => {
    const { refreshToken, isAuthorized } = store.getState().auth;
    try {
      if ( refreshToken ) {
        const { data } = await client.post( '/auth/refresh', { refreshToken } );
        processTokensPair( data );
      } else if ( isAuthorized ) {
        logout();
      }
    } catch {
      logout();
    }
  };
  return async () =>
    mutex.isLocked()
      ? mutex.acquire().then( release => release() )
      : mutex.runExclusive( refresh );
} )();

function processGoogleAuthRedirect() {
  const url = new URL( location.href );
  if ( url.pathname === '/gauth' ) {
    const accessToken = url.searchParams.get( 'accessToken' );
    const refreshToken = url.searchParams.get( 'refreshToken' );
    store.dispatch( authActions.setAccessToken( accessToken ) );
    store.dispatch( authActions.setRefreshToken( refreshToken ) );
  }
}

function logout() {
  store.dispatch( authActions.setAccessToken( null ) );
  store.dispatch( authActions.setRefreshToken( null ) );
}

function processTokensPair( tokensPair: TokensPair ) {
  store.dispatch( authActions.setAccessToken( tokensPair.accessToken ) );
  store.dispatch( authActions.setRefreshToken( tokensPair.refreshToken ) );
}

export const authService = {
  processGoogleAuthRedirect,
  logout,
  refreshMutex
};
