import {
  ChangeEvent,
  ClassAttributes,
  ClipboardEventHandler,
  FocusEventHandler,
  HTMLInputTypeAttribute
} from 'react';
import { concat } from '../../../utils';
import styles from './TextInput.module.scss';

export interface TextInputProps {
  value: string;
  onChange: ( value: string ) => void;
  type?: HTMLInputTypeAttribute;
  state?: 'invalid' | 'valid';
  placeholder?: string;
  onBlur?: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  onFocus?: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  onPaste?: ClipboardEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  className?: string;
  inputRef?: ClassAttributes<HTMLInputElement>['ref'];
  textarea?: boolean;
}

export const TextInput = ( {
  value,
  onChange,
  type,
  placeholder,
  onBlur,
  onFocus,
  onPaste,
  state,
  className,
  inputRef,
  textarea
}: TextInputProps ) => {
  const onInputChange = (
    event: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>
  ) => {
    onChange( event.target.value );
  };

  const props = {
    value: value,
    onChange: onInputChange,
    type: type,
    className: concat( styles['input'], state ? styles[state] : undefined, className ),
    placeholder: placeholder,
    onFocus: onFocus,
    onBlur: onBlur,
    onPaste: onPaste
  };

  return textarea ? (
    <textarea {...props}></textarea>
  ) : (
    <input
      ref={inputRef}
      {...props}
    />
  );
};
