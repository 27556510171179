import { SVGProps } from 'react';

export const CloseSmallIcon = ( {
  fill = '#828282',
  style,
  className,
  onClick
}: SVGProps<unknown> ) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    height='16'
    width='16'
    viewBox={'0 0 16 16'}
    fill={fill}
    style={style}
    className={className}
    onClick={onClick}
  >
    <path
      d='M5 4.003a1 1 0 0 0-.707 1.707l2.293 2.293-2.293 2.293A1 1 0 0 0 5 12.003a1 1 0 0 0 .707-.293L8 9.417l2.283 2.283A1 1 0 0 0 12 11.003a1 1 0 0 0-.293-.707L9.414 8.003l2.283-2.283A1 1 0 0 0 11 4.003a1 1 0 0 0-.707.293L8 6.589 5.717 4.306A1.01 1.01 0 0 0 5 4.003z'
      opacity='1'
    />
  </svg>
);
