import { configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { mainApi } from './api/redux';
import { appSlice, authSlice } from './slices';

export const store = configureStore( {
  reducer: {
    [mainApi.reducerPath]: mainApi.reducer,
    [authSlice.name]: authSlice.reducer,
    [appSlice.name]: appSlice.reducer
  },
  middleware: gDM => gDM().concat( mainApi.middleware )
} );

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;
export const useTypedDispatch: () => AppDispatch = useDispatch;

export type Store = typeof store;
