import { useEffect } from 'react';
import { authService } from '../../store/api';

export const GoogleAuth = () => {
  useEffect( () => {
    authService.processGoogleAuthRedirect();
  }, [] );

  return null;
};
