import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { AppSliceState } from './types';

const initialState: AppSliceState = {
  search: ''
};

export const appSlice = createSlice( {
  initialState,
  name: 'app',
  reducers: {
    setSearch: ( state, action: PayloadAction<AppSliceState['search']> ) => {
      state.search = action.payload;
    }
  }
} );

export const appActions = appSlice.actions;
