import { skipToken } from '@reduxjs/toolkit/dist/query';
import { DateTime } from 'luxon';
import { useParams } from 'react-router-dom';
import { AppScreenContainer } from '../../components/ui/global';
import {
  InfoItem,
  InfoItems,
  LoadingIndicator,
  infoItem
} from '../../components/ui/views';
import { useGetUserQuery } from '../../store/api';
import styles from './UserScreen.module.scss';

export const UserScreen = () => {
  const { id } = useParams();

  const { data: user } = useGetUserQuery( id || skipToken );

  const countClassNames: InfoItem['className'] = {
    item: styles['count'],
    content: styles['content'],
    wrapper: styles['wrapper'],
    label: styles['label']
  };

  const countInfoItem = ( ...params: Parameters<typeof infoItem> ) =>
    infoItem( params[0], params[1], countClassNames );

  return (
    <AppScreenContainer
      title={user?.name || ''}
      className={{
        container: styles['container'],
        main: styles['main'],
        content: styles['container-content']
      }}
    >
      {user ? (
        <InfoItems
          items={{
            email: infoItem( 'Email', user.email ),
            name: infoItem( 'Name', user.name ),
            registeredAt: infoItem(
              'Registered at',
              DateTime.fromISO( user.createdAt ).toFormat( 'f' )
            ),
            funnelsTotal: countInfoItem( 'Total', user.funnelsCount.total ),
            funnelsDraft: countInfoItem( 'Draft', user.funnelsCount.draft ),
            funnelsApproved: countInfoItem( 'Approved', user.funnelsCount.approved ),
            funnelsCanceled: countInfoItem( 'Canceled', user.funnelsCount.canceled ),
            funnelsCompleted: countInfoItem( 'Completed', user.funnelsCount.completed ),
            funnelsPaused: countInfoItem( 'Paused', user.funnelsCount.paused ),
            usageTotal: countInfoItem( 'Total', user.usage.totalTokens ),
            usagePrompt: countInfoItem( 'Prompt', user.usage.promptTokens ),
            usageCompletion: countInfoItem( 'Completion', user.usage.completionTokens )
          }}
          layout={[
            [ 'email', 'name', 'registeredAt' ],
            [
              'funnelsTotal',
              'funnelsCompleted',
              'funnelsDraft',
              'funnelsApproved',
              'funnelsCanceled',
              'funnelsPaused'
            ],
            [ 'usageTotal', 'usagePrompt', 'usageCompletion' ]
          ]}
          rowTitles={[ 'User info', 'Funnels', 'Tokens usage' ]}
        />
      ) : (
        <LoadingIndicator box />
      )}
    </AppScreenContainer>
  );
};
