import { concat } from '../../../utils';
import styles from './AuthButton.module.scss';

export interface AuthButtonProps {
  icon: JSX.Element;
  text: string;
  href: string;
  className?: string;
}

export const AuthButton = ( { icon, href, text, className }: AuthButtonProps ) => {
  return (
    <a
      className={concat( styles['auth-button'], className )}
      href={href}
    >
      {icon}
      <span>{text}</span>
    </a>
  );
};
