import { PropsWithChildren } from 'react';
import styles from './AuthScreenContainer.module.scss';

export type AuthScreenContainer = PropsWithChildren<{
  title: string | JSX.Element;
  image: string;
}>;

export const AuthScreenContainer = ( { title, children, image }: AuthScreenContainer ) => {
  return (
    <div className={styles['container']}>
      <div className={styles['content-wrapper']}>
        {/* <div className={styles['logo']}>LOGO HERE</div> */}
        <div className={styles['content']}>
          <h1>{title}</h1>
          {children}
        </div>
      </div>
      <div
        className={styles['img']}
        style={{ backgroundImage: `url('${image}')` }}
      ></div>
    </div>
  );
};
