import { Navigate, RouteObject, createBrowserRouter } from 'react-router-dom';
import { GoogleAuth } from '../components/service';
import { DashboardScreen, LogsScreen, SignInScreen, UserScreen } from '../screens';
import { UsersScreen } from '../screens/UsersScreen';

const asElementTypes = <T,>( et: {
  [K in keyof T]: RouteObject & { name?: string; icon?: JSX.Element } & Required<
      Pick<RouteObject, 'path'>
    >;
} ) => et;

export const authRoutes = asElementTypes( {
  index: {
    path: '/',
    element: <SignInScreen />,
    errorElement: <Navigate to='/' />
  },
  googleAuth: {
    path: '/gauth',
    element: <GoogleAuth />,
    errorElement: <Navigate to='/' />
  }
} );

export const appRoutes = asElementTypes( {
  index: {
    path: '/',
    element: <Navigate to='/dashboard' />,
    errorElement: <Navigate to='/dashboard' />
  },
  dashboard: {
    path: '/dashboard',
    element: <DashboardScreen />,
    name: 'Dashboard'
  },
  users: {
    path: '/users',
    element: <UsersScreen />,
    name: 'Users'
  },
  user: {
    path: '/users/:id',
    element: <UserScreen />
  },
  logs: {
    path: '/logs',
    element: <LogsScreen />,
    name: 'Logs'
  }
} );

export const authRouter = createBrowserRouter( Object.values( authRoutes ) );

export const appRouter = createBrowserRouter( Object.values( appRoutes ) );
