import { useEffect, useMemo, useState } from 'react';
import { LoadingIndicator } from '../LoadingIndicator';
import {
  TableListHeader,
  TableListHeaderProps,
  TableListItem,
  TableListItemProps,
  TableListPagination,
  TableListPaginationProps
} from './components';

import { concat } from '../../../../utils';
import styles from './TableList.module.scss';

export type TableListProps<
  Item extends object,
  CustomColumns extends string = never
> = Omit<TableListItemProps<Item, CustomColumns>, 'item'> &
  Pick<TableListHeaderProps<Item, CustomColumns>, 'sorting'> & {
    items: Item[];
    headings?: TableListHeaderProps<Item, CustomColumns>['headings'];
    defaultSorting?: TableListHeaderProps<Item, CustomColumns>['sortedBy'];
    onSortingChange?: (
      sorting: TableListHeaderProps<Item, CustomColumns>['sortedBy']
    ) => void;
    defaultPaginationState?: TableListPaginationProps<Item>['defaultState'];
    onPaginationStateChange?: TableListPaginationProps<Item>['onStateChange'];
    isLoading?: boolean;
    isFetching?: boolean;
    disablePagination?: boolean;
    tableClassName?: string;
  };

export const TableList = <Item extends object, CustomColumns extends string = never>( {
  items,
  display,
  headings,
  customColumns,
  link,
  transformations,
  sorting,
  defaultSorting,
  onSortingChange,
  defaultPaginationState,
  onPaginationStateChange,
  isLoading,
  isFetching,
  disablePagination = true,
  className,
  tableClassName,
  rowClassName
}: TableListProps<Item, CustomColumns> ) => {
  const itemsLocal = useMemo( () => {
    return [ ...items ];
  }, [ items ] );

  const [ currentSorting, setCurrentSorting ] = useState<
    keyof Item | CustomColumns | undefined
  >( defaultSorting?.key );
  const [ sortingOrder, setSortingOrder ] = useState<'desc' | 'asc'>(
    defaultSorting?.order || 'asc'
  );

  useEffect( () => {
    if ( onSortingChange ) {
      onSortingChange( {
        key: currentSorting,
        order: sortingOrder
      } );
    }
  }, [ onSortingChange, currentSorting, sortingOrder ] );

  const [ paginatedItems, setPaginatedItems ] = useState<Item[]>( [] );

  const onHeadingClick: TableListHeaderProps<Item, CustomColumns>['onSort'] = sorting
    ? key => {
        if ( !Object.keys( sorting ).includes( key as string ) ) return;
        if ( currentSorting === key ) {
          setSortingOrder( sortingOrder === 'asc' ? 'desc' : 'asc' );
        } else {
          setCurrentSorting( key );
          setSortingOrder( 'asc' );
        }
      }
    : undefined;

  const sortedItems = useMemo( () => {
    if ( !currentSorting || !sorting ) return itemsLocal;
    const sortingFuncCreator = sorting[currentSorting];
    if ( !sortingFuncCreator ) return itemsLocal;
    return itemsLocal.sort( sortingFuncCreator( sortingOrder ) );
  }, [ itemsLocal, currentSorting, sortingOrder, sorting ] );

  const displayItems = disablePagination ? sortedItems : paginatedItems;

  return (
    <div className={styles['table-list-wrapper']}>
      {isLoading ? (
        <LoadingIndicator box />
      ) : (
        <>
          <table className={concat( styles['table'], tableClassName )}>
            {headings && (
              <TableListHeader
                display={display}
                headings={headings}
                sorting={sorting}
                sortedBy={{
                  key: currentSorting,
                  order: sortingOrder
                }}
                onSort={onHeadingClick}
              />
            )}
            <tbody>
              {displayItems.map( ( item, index ) => (
                <TableListItem
                  key={index}
                  item={item}
                  display={display}
                  customColumns={customColumns}
                  link={link}
                  transformations={transformations}
                  className={className}
                  rowClassName={rowClassName}
                />
              ) )}
            </tbody>
          </table>
          {!disablePagination && (
            <TableListPagination
              items={sortedItems}
              onChange={setPaginatedItems}
              defaultState={defaultPaginationState}
              onStateChange={onPaginationStateChange}
            />
          )}
          {isFetching && <div className={styles['fetching']}></div>}
        </>
      )}
    </div>
  );
};
