import { skipToken } from '@reduxjs/toolkit/dist/query';
import { DateTime } from 'luxon';
import { useState } from 'react';
import { DateSelect } from '../../components/controls';
import { LabeledField } from '../../components/ui/controls';
import { AppScreenContainer } from '../../components/ui/global';
import { TableList } from '../../components/ui/views';
import { Log, useGetLogsQuery } from '../../store/api';
import styles from './LogsScreen.module.scss';

export const LogsScreen = () => {
  const [ start, setStart ] = useState<DateTime | undefined>(
    DateTime.now().minus( { days: 1 } ).startOf( 'day' )
  );
  const [ end, setEnd ] = useState<DateTime | undefined>( DateTime.now().endOf( 'day' ) );

  const onStartChange = ( dateTime: DateTime | undefined ) =>
    setStart( dateTime?.startOf( 'day' ) );
  const onEndChange = ( dateTime: DateTime | undefined ) => setEnd( dateTime?.endOf( 'day' ) );

  const { data: logs } = useGetLogsQuery(
    start && end ? { start: start.toJSDate(), end: end.toJSDate() } : skipToken
  );

  return (
    <AppScreenContainer title='Logs'>
      <div className={styles['filters']}>
        <LabeledField label='Start'>
          <DateSelect
            value={start}
            onChange={onStartChange}
            isClearable
            filterDate={date => DateTime.fromJSDate( date ).endOf( 'day' ) < DateTime.now()}
            placeholderText='Start'
            dateFormat='dd.MM.yyyy'
          />
        </LabeledField>
        <LabeledField
          label='End'
          className={styles['labeled-field']}
        >
          <DateSelect
            value={end}
            onChange={onEndChange}
            isClearable
            filterDate={date => DateTime.fromJSDate( date ) < DateTime.now()}
            placeholderText='Start'
            dateFormat='dd.MM.yyyy'
          />
        </LabeledField>
      </div>
      {logs && (
        <TableList<Log, 'url' | 'method' | 'user'>
          items={logs}
          display={[ 'url', 'method', 'user', 'timestamp' ]}
          transformations={{
            timestamp: dateString => DateTime.fromISO( dateString ).toFormat( 'f' )
          }}
          customColumns={{
            method: log => log.meta.method,
            url: log => log.meta.url,
            user: log => log.meta.user?.userId || 'unauthorized'
          }}
          disablePagination={false}
          headings={{
            timestamp: 'Timestamp',
            url: 'URL',
            user: 'User',
            method: 'Method'
          }}
        />
      )}
    </AppScreenContainer>
  );
};
