import styles from './LoadingIndicator.module.scss';

export interface LoadingIndicatorProps {
  box?: boolean;
}

export const LoadingIndicator = ( { box }: LoadingIndicatorProps ) => {
  const spinner = <div className={styles['loading-indicator']}></div>;

  return box ? <div className={styles['box']}>{spinner}</div> : spinner;
};
