import { FunnelsCount } from '../models';
import { mainApi } from './mainApi';

export const funnelsApi = mainApi.injectEndpoints( {
  endpoints: build => ( {
    countFunnels: build.query<FunnelsCount, void>( {
      query: () => ( {
        url: '/funnels/count'
      } ),
      providesTags: [ { type: 'funnels', id: 'count' } ]
    } )
  } )
} );

export const { useCountFunnelsQuery } = funnelsApi;
