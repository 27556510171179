export const localStoragePaths = {
  accessToken: 'fu_access_token',
  refreshToken: 'fu_refresh_token'
};

export interface AuthSliceState {
  isAuthorized: boolean;
  accessToken: string | null;
  refreshToken: string | null;
}

export interface AppSliceState {
  search: string;
}
