import { SearchIcon } from '../../../utils/icons';
import { TextInput, TextInputProps } from '../TextInput';
import styles from './SearchInput.module.scss';

export type SearchInputProps = Pick<TextInputProps, 'value' | 'onChange'>;

export const SearchInput = ( { value, onChange }: SearchInputProps ) => {
  return (
    <div className={styles['search']}>
      <TextInput
        value={value}
        onChange={onChange}
        className={styles['input']}
        placeholder='Search here....'
      />
      <div className={styles['icon']}>
        <SearchIcon />
      </div>
    </div>
  );
};
