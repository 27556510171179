import { concat } from '../../../../utils';
import styles from './Tabs.module.scss';

export interface TabsProps<Values extends string> {
  tabs: { value: Values; label: string }[];
  value: Values;
  onChange: ( value: Values ) => void;
  className?: {
    container?: string;
    tab?: string;
  };
}

export const Tabs = <Values extends string>( {
  value,
  onChange,
  tabs,
  className
}: TabsProps<Values> ) => {
  const onTabClick = ( v: Values ) => () => onChange( v );

  return (
    <div className={concat( styles['tabs'], className?.container )}>
      {tabs.map( ( { value: tabValue, label }, index ) => (
        <div
          key={index}
          className={concat( styles['tab'], className?.tab )}
          onClick={onTabClick( tabValue )}
          data-current={tabValue === value}
        >
          {label}
        </div>
      ) )}
    </div>
  );
};
