import avatar from '../../../../../assets/img/avatar.png';
import { useTypedDispatch, useTypedSelector } from '../../../../../store';
import { useGetCurrentUserQuery } from '../../../../../store/api';
import { appActions } from '../../../../../store/slices';
import { ArrowDownIcon, BellIcon } from '../../../../../utils/icons';
import { SearchInput } from '../../../../controls';
import styles from '../AppScreenContainer.module.scss';

export const Header = () => {
  const { data: user } = useGetCurrentUserQuery();

  const { search } = useTypedSelector( state => state.app );
  const dispatch = useTypedDispatch();

  const setSearch = ( value: string ) => {
    dispatch( appActions.setSearch( value ) );
  };

  return (
    <div className={styles['header']}>
      <div className={styles['search']}>
        <SearchInput
          value={search}
          onChange={setSearch}
        />
      </div>
      <div className={styles['actions']}>
        <div className={styles['notifications']}>
          <BellIcon />
        </div>
        <div className={styles['profile']}>
          <div className={styles['info']}>
            <span className={styles['name']}>{user?.name}</span>
            <span className={styles['email']}>{user?.email}</span>
          </div>
          <div className={styles['avatar']}>
            <img src={avatar} />
            <div className={styles['status']}></div>
          </div>
          <div className={styles['actions']}>
            <ArrowDownIcon />
          </div>
        </div>
      </div>
    </div>
  );
};
