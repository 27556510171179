import { Log } from '../models';
import { GetLogsParams } from '../params';
import { mainApi } from './mainApi';

export const logsApi = mainApi.injectEndpoints( {
  endpoints: build => ( {
    getLogs: build.query<Log[], GetLogsParams>( {
      query: params => ( {
        url: '/logs',
        params
      } ),
      providesTags: ( res, err, arg ) =>
        err ? [] : [ { type: 'logs', id: JSON.stringify( arg ) } ]
    } )
  } )
} );

export const { useGetLogsQuery } = logsApi;
