import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { AuthSliceState, localStoragePaths } from './types';

const initialState: AuthSliceState = {
  isAuthorized: !!localStorage.getItem( localStoragePaths.accessToken ),
  accessToken: localStorage.getItem( localStoragePaths.accessToken ),
  refreshToken: localStorage.getItem( localStoragePaths.refreshToken )
};

export const authSlice = createSlice( {
  initialState,
  name: 'auth',
  reducers: {
    setIsAuthorized: ( state, action: PayloadAction<AuthSliceState['isAuthorized']> ) => {
      state.isAuthorized = action.payload;
    },
    setAccessToken: ( state, action: PayloadAction<AuthSliceState['accessToken']> ) => {
      state.accessToken = action.payload;
      state.isAuthorized = !!action.payload;
      if ( action.payload ) {
        localStorage.setItem( localStoragePaths.accessToken, action.payload );
      } else {
        localStorage.removeItem( localStoragePaths.accessToken );
      }
    },
    setRefreshToken: ( state, action: PayloadAction<AuthSliceState['refreshToken']> ) => {
      state.refreshToken = action.payload;
      if ( action.payload ) {
        localStorage.setItem( localStoragePaths.refreshToken, action.payload );
      } else {
        localStorage.removeItem( localStoragePaths.refreshToken );
      }
    }
  }
} );

export const authActions = authSlice.actions;
