import { AppScreenContainer } from '../../components/ui/global';
import { InfoItem, InfoItems, infoItem } from '../../components/ui/views';
import { useCountFunnelsQuery, useCountUsersQuery } from '../../store/api';
import styles from './DashboardScreen.module.scss';

export const DashboardScreen = () => {
  const { data: usersCount } = useCountUsersQuery();
  const { data: funnelsCount } = useCountFunnelsQuery();

  const countClassNames: InfoItem['className'] = {
    item: styles['count'],
    content: styles['content'],
    wrapper: styles['wrapper'],
    label: styles['label']
  };

  const countInfoItem = ( ...params: Parameters<typeof infoItem> ) =>
    infoItem( params[0], params[1], countClassNames );

  return (
    <AppScreenContainer
      title='Dashboard'
      className={{
        container: styles['container'],
        content: styles['container-content'],
        main: styles['main']
      }}
    >
      <InfoItems
        items={{
          usersCount: countInfoItem( 'Total users', usersCount || '-' ),
          funnelsTotal: countInfoItem( 'Total', funnelsCount?.total || '-' ),
          funnelsDraft: countInfoItem( 'Draft', funnelsCount?.draft || '-' ),
          funnelsApproved: countInfoItem( 'Approved', funnelsCount?.approved || '-' ),
          funnelsCanceled: countInfoItem( 'Canceled', funnelsCount?.canceled || '-' ),
          funnelsCompleted: countInfoItem( 'Completed', funnelsCount?.completed || '-' ),
          funnelsPaused: countInfoItem( 'Paused', funnelsCount?.paused || '-' )
        }}
        layout={[
          [ 'usersCount' ],
          [
            'funnelsTotal',
            'funnelsCompleted',
            'funnelsDraft',
            'funnelsApproved',
            'funnelsCanceled',
            'funnelsPaused'
          ]
        ]}
        rowTitles={[ 'Users', 'Funnels' ]}
      />
    </AppScreenContainer>
  );
};
