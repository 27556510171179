import { concat } from '../../../../utils';
import styles from './InfoItems.module.scss';

export interface InfoItem {
  label: string;
  content: string | number | JSX.Element;
  className?: {
    item?: string;
    label?: string;
    content?: string;
    wrapper?: string;
  };
}

export interface InfoItemsProps<Items extends string> {
  items: Record<Items, InfoItem>;
  rowTitles?: ( string | null | undefined )[];
  layout: Items[][];
}

export const infoItem = (
  label: InfoItem['label'],
  content: InfoItem['content'],
  className?: InfoItem['className']
) => ( { content, label, className } as InfoItem );

export const InfoItems = <Items extends string>( {
  items,
  layout,
  rowTitles
}: InfoItemsProps<Items> ) => {
  return (
    <div className={styles['info-items']}>
      {layout.map( ( row, rowIndex ) => (
        <div
          key={rowIndex}
          className={styles['row']}
        >
          {rowTitles?.[rowIndex] && <h2>{rowTitles[rowIndex]}</h2>}
          <div className={styles['content']}>
            {row.map( ( key, index ) => {
              const { content, label, className } = items[key];

              return (
                <div
                  className={concat( styles['item'], className?.item )}
                  key={index}
                >
                  <div className={concat( styles['wrapper'], className?.wrapper )}>
                    <span className={concat( styles['label'], className?.label )}>
                      {label}
                    </span>
                    <div className={concat( styles['content'], className?.content )}>
                      {content}
                    </div>
                  </div>
                </div>
              );
            } )}
          </div>
        </div>
      ) )}
    </div>
  );
};
