import background from '../../assets/img/auth-bg.jpg';
import { AuthButton } from '../../components/controls';
import { AuthScreenContainer } from '../../components/ui/global';
import { baseUrl } from '../../store/api';
import { GmailIcon } from '../../utils/icons';
import styles from './SignInScreen.module.scss';

export const SignInScreen = () => {
  return (
    <AuthScreenContainer
      title={
        <>
          <span>Follow-up.ai</span>
          <br />
          Dashboard
        </>
      }
      image={background}
    >
      <AuthButton
        icon={<GmailIcon />}
        text='Gmail'
        className={styles['auth-button']}
        href={`${baseUrl}/auth/google?dashboard=true`}
      />
    </AuthScreenContainer>
  );
};
