import { useState } from 'react';
import { AppScreenContainer } from '../../components/ui/global';
import { TableList, TableListProps } from '../../components/ui/views';
import { appRoutes } from '../../navigation';
import { User, useGetUsersQuery } from '../../store/api';

export const UsersScreen = () => {
  const { data: users } = useGetUsersQuery();

  const [ pagination, setPagination ] = useState<
    TableListProps<User>['defaultPaginationState']
  >( { itemOffset: 0, itemsPerPage: 25 } );

  return (
    <AppScreenContainer title='Users'>
      <TableList<User, 'tokensUsage' | 'funnels'>
        items={users?.users || []}
        display={[ 'email', 'name', 'funnels', 'tokensUsage' ]}
        headings={{
          email: 'Email',
          name: 'Name',
          funnels: 'Funnels',
          tokensUsage: 'Tokens usage'
        }}
        defaultPaginationState={pagination}
        onPaginationStateChange={setPagination}
        disablePagination={false}
        link={user => appRoutes.user.path.replace( ':id', user._id )}
        customColumns={{
          funnels: user => user.funnelsCount.total,
          tokensUsage: user => user.usage.totalTokens
        }}
      />
    </AppScreenContainer>
  );
};
