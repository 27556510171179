/* eslint-disable react/display-name */
import { MouseEventHandler } from 'react';
import { CloseSmallIcon } from './ClearIcon';
import styles from './DateSelect.module.scss';

export const DateSelectInputClearable =
  ( onClear: MouseEventHandler ) =>
  (
    props: React.HTMLProps<HTMLInputElement>,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    ref: React.Ref<HTMLInputElement>
  ) => {
    return (
      <div className={styles['date-select-input']}>
        <input
          {...props}
          ref={ref}
        />
        <div className={styles['icon-clear']}>
          <CloseSmallIcon onClick={onClear} />
        </div>
      </div>
    );
  };
