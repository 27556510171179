import { Provider } from 'react-redux';
import './assets/scss/base.scss';
import { RootRouter } from './navigation';
import { store } from './store';
import { servicesInjectStore } from './store/api';

servicesInjectStore( store );

export const App = () => {
  return (
    <Provider store={store}>
      <RootRouter />
    </Provider>
  );
};
