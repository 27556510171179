import { SVGProps } from 'react';

export const ArrowDownFillIcon = ( {
  fill = '#828282',
  style,
  className
}: SVGProps<unknown> ) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='10'
    height='7'
    fill='none'
    viewBox='0 0 10 7'
    style={style}
    className={className}
  >
    <path
      d='M9 1.334l-4 4-4-4h8z'
      fill={fill}
      stroke={fill}
      strokeWidth='2'
      strokeLinejoin='round'
    />
  </svg>
);
