import { ButtonHTMLAttributes, HTMLAttributeAnchorTarget, useMemo } from 'react';
import { Link, To } from 'react-router-dom';
import { concat } from '../../../utils';
import styles from './Button.module.scss';

export interface ButtonProps {
  text: string | JSX.Element;
  onClick?: () => void;
  href?: string;
  target?: HTMLAttributeAnchorTarget;
  type?: ButtonHTMLAttributes<HTMLButtonElement>['type'];
  className?: string;
  style?: 'small' | 'transparent-small' | 'alt' | 'red' | 'amber';
  disabled?: boolean;
  to?: To;
}

export const Button = ( {
  text,
  href,
  onClick,
  target,
  type,
  className,
  style,
  disabled,
  to
}: ButtonProps ) => {
  const buttonClassName = useMemo(
    () => concat( styles['button'], className, style ? styles[style] : undefined ),
    [ className, style ]
  );

  if ( to ) {
    return (
      <Link
        to={to}
        target={target}
        className={buttonClassName}
      >
        {text}
      </Link>
    );
  }

  if ( href ) {
    return (
      <a
        href={href}
        target={target}
        className={buttonClassName}
      >
        {text}
      </a>
    );
  }

  return (
    <button
      onClick={onClick}
      className={buttonClassName}
      type={type}
      disabled={disabled}
    >
      {text}
    </button>
  );
};
