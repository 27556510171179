import { RouterProvider } from 'react-router-dom';
import { useTypedSelector } from '../store/store';
import { appRouter, authRouter } from './routes';

export const RootRouter = () => {
  const { isAuthorized } = useTypedSelector( state => state.auth );

  console.log( { isAuthorized } );

  return <RouterProvider router={isAuthorized ? appRouter : authRouter} />;
};
