import { PropsWithChildren } from 'react';
import { concat } from '../../../../utils';
import styles from './LabeledField.module.scss';

export type LabeledFieldProps = PropsWithChildren<{
  label: string;
  state?: 'invalid' | 'valid';
  className?: string;
}>;

export const LabeledField = ( {
  label,
  children,
  state,
  className
}: LabeledFieldProps ) => {
  return (
    <label
      className={concat(
        styles['labeled-field'],
        state ? styles[state] : undefined,
        className
      )}
    >
      <span className={styles['label']}>{label}</span>
      {children}
    </label>
  );
};
