import { DateTime } from 'luxon';
import React, { MouseEventHandler } from 'react';
import ReactDatePicker, { ReactDatePickerProps } from 'react-datepicker';
// import 'react-datepicker/dist/react-datepicker.css';
import './DatePickerOverwrite.scss';
import styles from './DateSelect.module.scss';
import { DateSelectInput } from './DateSelectInput';
import { DateSelectInputClearable } from './DateSelectInputClearable';

export interface DateSelectProps
  extends Pick<
    ReactDatePickerProps,
    'dateFormat' | 'showTimeSelect' | 'filterTime' | 'filterDate' | 'placeholderText'
  > {
  value: DateTime | undefined;
  onChange: ( value: DateTime | undefined ) => void;
  isClearable?: boolean;
}

export const DateSelect = ( {
  value,
  onChange,
  isClearable,
  ...selectProps
}: DateSelectProps ) => {
  const onPickerChange: ReactDatePickerProps['onChange'] = date => {
    if ( date ) onChange( DateTime.fromJSDate( date ) );
  };

  const clear: MouseEventHandler = e => {
    e.preventDefault();
    e.stopPropagation();
    onChange( undefined );
  };

  return (
    <div className={styles['date-select-wrapper']}>
      <ReactDatePicker
        onChange={onPickerChange}
        selected={value?.toJSDate()}
        customInput={React.createElement(
          React.forwardRef(
            isClearable && !!value ? DateSelectInputClearable( clear ) : DateSelectInput
          )
        )}
        {...selectProps}
      />
    </div>
  );
};
