import { GetUsersResponse, User } from '../models';
import { GetUsersParams } from '../params';
import { mainApi } from './mainApi';

export const usersApi = mainApi.injectEndpoints( {
  endpoints: build => ( {
    getCurrentUser: build.query<User, void>( {
      query: () => ( {
        url: '/users/me'
      } ),
      providesTags: [ 'currentUser' ]
    } ),
    getUser: build.query<User, string>( {
      query: id => ( {
        url: `/users/${id}`
      } ),
      providesTags: ( res, err, id ) => ( err ? [] : [ { type: 'users', id } ] )
    } ),
    getUsers: build.query<GetUsersResponse, GetUsersParams | void>( {
      query: params => ( {
        url: '/users',
        params
      } ),
      providesTags: ( res, err, args ) =>
        err ? [] : [ { type: 'users', id: JSON.stringify( args ) } ]
    } ),
    countUsers: build.query<number, void>( {
      query: () => ( {
        url: '/users/count'
      } ),
      providesTags: [ { type: 'users', id: 'count' } ]
    } )
  } )
} );

export const {
  useGetUserQuery,
  useGetCurrentUserQuery,
  useGetUsersQuery,
  useCountUsersQuery
} = usersApi;
