export const concat = ( ...names: ( string | undefined )[] ) => names.join( ' ' ).trim();

export const parseMessageName = ( emailValue: string ) => {
  const match = emailValue.match( '(.*) <.*>' );
  if ( !match ) return '-';
  return match[1];
};

export const parseMessageEmailOrName = ( emailValue: string ) => {
  const match = emailValue.match( '(.*) <.*>' );
  return match?.[1] || emailValue;
};

export const parseMessageEmail = ( emailValue: string ) => {
  const composedEmailMatch = emailValue.match( /.+ <(.*)>/ );
  if ( composedEmailMatch?.[1] ) return composedEmailMatch[1];
  const match = emailValue.match( /.+@.+/ );
  console.log( { emailValue, match, composedEmailMatch } );
  if ( !match ) return '';
  return match[0];
};

export const getMessageExcerpt = ( text: string | undefined ) => {
  let split = text?.split( ' ' ) || [];
  let modified = false;
  while ( split.reduce( ( total, word ) => total + word.length, 0 ) > 74 ) {
    split = split.slice( 0, split.length - 1 );
    modified = true;
  }
  return modified ? split.join( ' ' ) + '...' : split.join( ' ' );
};
