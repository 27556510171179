import { useMemo } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { appRoutes } from '../../../../../navigation';
import { authService } from '../../../../../store/api';
import { LogoutIcon } from '../../../../../utils/icons';
import styles from '../AppScreenContainer.module.scss';

export interface NavPanelProps {
  isOpen?: boolean;
}

export const NavPanel = ( { isOpen }: NavPanelProps ) => {
  const navigate = useNavigate();
  const location = useLocation();

  const onLogout = () => {
    authService.logout();
    navigate( '/' );
  };

  const panelItems = useMemo( () => {
    return Object.values( appRoutes ).filter( item => !!item.name );
  }, [ appRoutes ] );

  return (
    <div
      className={styles['nav-panel']}
      data-open={isOpen}
    >
      <nav className={styles['nav']}>
        <div className={styles['logo']}>logo here</div>
        <ul>
          {panelItems.map( ( { name, icon, path }, index ) => (
            <li key={index}>
              <Link
                to={path}
                data-current={location.pathname.includes( path )}
              >
                <span>
                  {icon}
                  {name}
                </span>
              </Link>
            </li>
          ) )}
        </ul>
      </nav>
      <div className={styles['logout']}>
        <span onClick={onLogout}>
          <LogoutIcon />
          Logout
        </span>
      </div>
    </div>
  );
};
